import { Injectable, Signal, WritableSignal, signal } from "@angular/core";
import { Contract } from "@interfaces";
import { getContract, getContractValues } from "@queries/Contract";
import { getDashboard } from "@queries/User";
import { Apollo, ApolloBase, gql } from "apollo-angular";
import { first, map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ContractService {
	private css = "color:#27ae60;font-weight:bold";

	private _showContractSettings: WritableSignal<Contract | null> = signal(null);

	public get showContractSettings(): Signal<Contract | null> {
		return this._showContractSettings;
	}

	public set showContractSettings(val: Contract | null) {
		this._showContractSettings.set(val);
	}

	private _currentContract: WritableSignal<Contract | null> = signal(null);

	public get currentContract(): Signal<Contract | null> {
		return this._currentContract;
	}

	public set currentContract(contract: Contract | null) {
		this._currentContract.set(contract);
	}

	private _userContracts: WritableSignal<Contract[]> = signal([]);
	public get userContracts(): Signal<Contract[]> {
		return this._userContracts;
	}

	private APOLLO: ApolloBase;
	constructor(private apolloProvider: Apollo) {
		this.APOLLO = this.apolloProvider.use("mainAPI");
	}

	// public selectContract(id: string) {
	// 	this.currentContract = this.userContracts().find(c => c.id === id) ?? null;
	// }

	/*****************************************************
	 * GET
	 *****************************************************/

	// public setUserContracts() {
	// 	this.getAllContracts()
	// 		.pipe(first())
	// 		.subscribe(({ data }) => {
	// 			const userContracts = data?.me?.Contracts;
	// 			if (userContracts) {
	// 				this.setContracts(userContracts);
	// 			}
	// 		});
	// }

	// ACTION CONTRACT LOAD MORE
	public getContractAction = (id: string | number, isOtherContract = false) => {
		return this.getContract(id, isOtherContract)
			.pipe(
				map(({ data }) => data?.Contract),
				first()
			)
			.subscribe((contract: Contract) => this.setContracts([contract]));
	};
	// public getContractValuesAction = (id: string, limit: number) => {
	// 	return this.getContractValues(id, limit)
	// 		.pipe(
	// 			map(({ data }) => data?.Contract),
	// 			first()
	// 		)
	// 		.subscribe(contract => this.setContracts([contract]));
	// };

	public setContracts = (contracts: Contract[]) => {
		const state = [...this.userContracts()];
		contracts.forEach(contract => {
			const index = state.findIndex(c => c.id === contract.id);
			if (index > -1) {
				state[index] = { ...state[index], ...contract };
			} else {
				state.push(contract);
			}
		});
		this._userContracts.set(state);
	};

	/*****************************************************
	 * REQUESTS
	 *****************************************************/
	// public getAllContracts() {
	// 	return this.APOLLO
	// 		.watchQuery<any>({
	// 			query: getAllContracts,
	// 			fetchPolicy: "cache-and-network"
	// 		})
	// 		.valueChanges.pipe(
	// 			tap((contracts: any) =>
	// 				console.log(`%cBOOST get ALL user's contracts :`, this.css, contracts)
	// 			)
	// 		);
	// }
	public getDashboard() {
		return this.APOLLO.watchQuery<any>({
			query: getDashboard,
			fetchPolicy: "cache-and-network"
		}).valueChanges.pipe(map(d => d?.data?.me?.Contracts));
	}
	// public getAllConnections() {
	// 	return this.APOLLO.watchQuery<any>({
	// 		query: getAllConnections,
	// 		fetchPolicy: "cache-and-network"
	// 	}).valueChanges;
	// }
	public getContract(id: string | number, isOtherContract: boolean) {
		// let query = isOtherContract ? getOtherContract : getContract;
		let query = getContract;

		return this.APOLLO.watchQuery<any>({
			query: query,
			variables: { id },
			fetchPolicy: "cache-and-network"
			//fetchPolicy: "network-only"
		}).valueChanges;
	}
	public getContractValues = (id: string, limit: number) => {
		return this.APOLLO.watchQuery<any>({
			query: getContractValues,
			variables: { id, limit },
			fetchPolicy: "cache-and-network"
			//fetchPolicy: "network-only"
		}).valueChanges;
	};

	/*****************************************************
	 * EDIT
	 *****************************************************/

	public saveContract(contract: any): any {
		return this.APOLLO.mutate({
			mutation: gql`
				mutation saveContract($contract: DataObjectType!) {
					saveContract(contract: $contract)
				}
			`,
			variables: {
				contract: contract
			}
		});
	}

	public exchangeCodeBiToken(code: string): any {
		console.log("exchangeCodeBiToken: ", code);
		return this.APOLLO.mutate({
			mutation: gql`
				mutation exchangeCodeBiToken($code: String!) {
					exchangeCodeBiToken(code: $code)
				}
			`,
			variables: {
				code: code
			}
		});
	}

	/*****************************************************
	 * USELESS FOR NOW
	 *****************************************************/

	public deleteContract(connectionId: any) {
		// String !!
		console.log(typeof connectionId);
		connectionId = Number(connectionId);
		return this.APOLLO.mutate({
			mutation: gql`
				mutation deleteContract($connectionId: Int!) {
					deleteContract(connectionId: $connectionId)
				}
			`,
			variables: {
				connectionId
			}
		}).pipe(
			map(result => {
				// console.log(result);
				return result;
			})
		);
	}
	public deleteBOConnection(connectionId: any) {
		connectionId = Number(connectionId);
		return this.APOLLO.mutate({
			mutation: gql`
				mutation deleteConnection($connectionId: Int!) {
					deleteConnection(connectionId: $connectionId)
				}
			`,
			variables: {
				connectionId
			}
		});
	}

	/*****************************************************
	 * SOCIAL
	 *****************************************************/
	// public getOtherContractValues(id: string, limit: number) {
	// 	return this.APOLLO.watchQuery<any>({
	// 		query: getOtherContractValues,
	// 		variables: { id, limit },
	// 		fetchPolicy: "cache-and-network"
	// 		//fetchPolicy: "network-only"
	// 	}).valueChanges;
	// }
	// public getLastContracts(limit: number = 6) {
	// 	return this.APOLLO.watchQuery<any>({
	// 		query: getLastContracts,
	// 		variables: { limit }
	// 	}).valueChanges;
	// }
	// public getContractByBIId(BIId: number) {
	// 	//	console.log("getContractByBIId: ", BIId);
	// 	return this.APOLLO.watchQuery<any>({
	// 		query: getContractByBIId,
	// 		variables: { BIId },
	// 		fetchPolicy: "network-only" // besoin de réactivité sur l'edition de contrat
	// 		//fetchPolicy: "cache-and-network"
	// 	}).valueChanges;
	// }
	// public searchContracts(filter: any) {
	// 	return this.APOLLO.watchQuery<any>({
	// 		query: searchContracts,
	// 		variables: { filter },
	// 		fetchPolicy: "network-only"
	// 		//fetchPolicy: "cache-and-network"
	// 		//fetchPolicy: "cache-first"
	// 	}).valueChanges;
	// }
	// public followToggle(
	// 	contractID: string,
	// 	isFollower: boolean,
	// 	refresh?: { dashboard?: boolean; userContractId?: string }
	// ) {
	// 	const query = isFollower
	// 		? this.unFollowContract(contractID)
	// 		: this.followContract(contractID, refresh);

	// 	return query.pipe(first()).subscribe();
	// }
	// public followContract(
	// 	tiersContractId: string,
	// 	refresh?: { dashboard?: boolean; userContractId?: string }
	// ) {
	// 	const refetchQueries: any[] = [
	// 		{
	// 			query: getTierContract,
	// 			variables: {
	// 				id: tiersContractId
	// 			}
	// 		}
	// 	];
	// 	if (refresh?.dashboard)
	// 		refetchQueries.push({
	// 			query: getDashboard
	// 		});
	// 	if (refresh?.userContractId)
	// 		refetchQueries.push({
	// 			query: getContract,
	// 			variables: {
	// 				id: refresh.userContractId
	// 			}
	// 		});

	// 	return this.APOLLO.mutate({
	// 		mutation: followContract,
	// 		variables: {
	// 			contractId: tiersContractId
	// 		},
	// 		refetchQueries
	// 	});
	// }
	// public unFollowContract(
	// 	tiersContractId: string,
	// 	refresh?: { dashboard?: boolean; userContractId?: string }
	// ) {
	// 	const refetchQueries: any[] = [
	// 		{
	// 			query: getTierContract,
	// 			variables: {
	// 				id: tiersContractId
	// 			}
	// 		}
	// 	];
	// 	if (refresh?.dashboard)
	// 		refetchQueries.push({
	// 			query: getDashboard
	// 		});
	// 	if (refresh?.userContractId)
	// 		refetchQueries.push({
	// 			query: getContract,
	// 			variables: {
	// 				id: refresh?.userContractId
	// 			}
	// 		});

	// 	return this.APOLLO.mutate({
	// 		mutation: unFollowContract,
	// 		variables: {
	// 			contractId: tiersContractId
	// 		},
	// 		refetchQueries
	// 	});
	// }
	// public getTierContract(id: string) {
	// 	return this.APOLLO.watchQuery<any>({
	// 		query: getTierContract,
	// 		variables: { id },
	// 		fetchPolicy: "network-only"
	// 		// fetchPolicy: 'cache-and-network'
	// 	}).valueChanges;
	// }

	// public getTopContracts(
	// 	top: "best" | "worst",
	// 	refContractId: string | number | null,
	// 	risk: number | null
	// ) {
	// 	const method =
	// 		top === "best"
	// 			? this.bestContracts(refContractId, risk)
	// 			: this.worstContracts(refContractId, risk);

	// 	return method.pipe(
	// 		map(({ data, loading }) => ({
	// 			loading,
	// 			data: data.bestContracts || data.worstContracts
	// 		}))
	// 	);
	// }

	// public bestContracts(
	// 	refContractId: string | number | null,
	// 	risk: number | null
	// ) {
	// 	const variables = {
	// 		refContractId,
	// 		risk,
	// 		limit: 3
	// 	};
	// 	return this.APOLLO.watchQuery<any>({
	// 		query: bestContracts,
	// 		variables
	// 		// fetchPolicy: 'network-only'
	// 		// fetchPolicy: 'cache-and-network'
	// 	}).valueChanges;
	// }

	// public worstContracts(
	// 	refContractId: string | number | null,
	// 	risk: number | null
	// ) {
	// 	const variables = {
	// 		refContractId,
	// 		risk,
	// 		limit: 3
	// 	};
	// 	return this.APOLLO.watchQuery<any>({
	// 		query: worstContracts,
	// 		variables
	// 		// fetchPolicy: 'network-only'
	// 		// fetchPolicy: 'cache-and-network'
	// 	}).valueChanges;
	// }
	// public compareContracts(compareContractsIds: string[]) {
	// 	return this.APOLLO.watchQuery<any>({
	// 		query: compareContracts,
	// 		variables: { compareContractsIds }
	// 		// fetchPolicy: 'network-only'
	// 		// fetchPolicy: 'cache-and-network'
	// 	}).valueChanges;
	// }
}
